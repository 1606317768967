/**
     * @description: 递归遍历查找数组对象的某个属性值
     * @param {string} code
     * @param [{array}] arr
	 * @returns {object}
     */
 export const filterArrObj =(code,val, arr) =>{
    for (let k=0;k<arr.length;k++){
      if (arr[k][code] ===val ){
        return arr[k]
      }
    }
 
  }
  /**
    * @description: 递归遍历查找数组对象的某个key & value , 判断是否存在
    * @param {string} key
    * @param {string} val
    * @param {array} arr
    * @returns {boolean}
 */
   export const existArrObj =(key,val, arr) =>{
    while (arr.length) {
      const firstobj = arr.shift()  //返回数组中第一个元素 ，原数组将减少一个无素
      if (firstobj[key] === val) return true
    }
  }
  // export const guid =() =>{
  //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  //       var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
  //       return v.toString(16);
  //     })
  // }
  export const uuid =()=>{ 
    var d = new Date()
    var time = d.getFullYear()  +(d.getMonth()+1)   + d.getDate()  + d.getHours() + d.getMinutes()  + d.getSeconds()+Math.round(Math.random() * 10000);
    return time
  }
 
  /***
   * @description: 通过一个数组参数 来构建一个表列字段属性集合 的数组
   * @param {Array}
   * @returns {Array}
   */
  
 

 